<template>
  <b-card>
    <!-- Header (filter, search bar, add button) -->
    <div class="app-table-header">
      <slot name="header" />
    </div>
    <!-- Data table -->
    <div class="app-table">
      <slot name="table" />
    </div>
    <!-- Paginating footer -->
    <div class="app-table-footer">
      <pagination-footer />
    </div>
  </b-card>
</template>

<script>
import {
  BCard,
} from 'bootstrap-vue'
import PaginationFooter from '@/views/components/table/PaginationFooter.vue'

export default {
  components: {
    BCard,
    PaginationFooter,
  },
}
</script>

<style lang="scss">
@import 'src/@core/scss/vue/libs/vue-select';
.app-table-header {
  margin-top: 10px;
  margin-bottom: 40px;
  .header-row {
    margin-bottom: 20px;
    display: flex;
    flex-direction: row;
    align-items: center;
    white-space: nowrap !important;

    .filter-group {
      overflow: auto;
      display: inline-block;
    }

    .select-option {
      min-width: 20%;
      margin-right:20px;
    }

    .select-option-small {
      min-width: 15%;
      margin-right:20px;
    }

    .add-btn {
      padding: 10px;
      margin-left: 20px;
    }
  }
}
.app-table {
  margin-bottom: 20px;
  min-height: 40vh;
  .table-content{
      text-align: center;
  }
}
.app-table-footer {
  margin-top: 20px;
  margin-bottom: 10px;
}
</style>
