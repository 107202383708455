<template>
  <div>
    <loading-component
      v-if="loading"
    />
    <error-component
      v-else-if="error"
      :error="error"
    />
    <app-table-view v-else>
      <template #header>
        <slot-list-header />
      </template>
      <template #table>
        <slot-list-table />
      </template>
    </app-table-view>
  </div>
</template>

<script>
import {
  onMounted, watch,
} from '@vue/composition-api'
import useSlotList from '@/views/utils/slot/useSlotList'
import useSlotTask from '@/views/utils/task/useSlotTask'
import AppTableView from '@/views/layouts/AppTableView.vue'
import SlotListHeader from '@/views/components/table/headers/SlotListHeader.vue'
import SlotListTable from '@/views/components/table/tables/SlotListTable.vue'

export default {
  components: {
    AppTableView,
    SlotListHeader,
    SlotListTable,
  },
  setup() {
    const {
      setCurrentSlots,
      searchQuery,
    } = useSlotList()
    const {
      loading,
      error,
      fetchSlotList,
    } = useSlotTask()

    watch([searchQuery], async () => {
      const slots = await fetchSlotList(searchQuery.value)
      setCurrentSlots(slots)
    })

    onMounted(async () => {
      const slots = await fetchSlotList()
      setCurrentSlots(slots)
    })

    return {
      loading,
      error,
    }
  },
}
</script>
