/* eslint-disable */
export default function useNumber() {
  const toPercent = val => (!val ? '-' : `${val.toFixed(2)}%`)
  const toMoney = val => (!val ? '-' : `₩${numberWithCommas(val)}`)
  const toBigNumber = val => {
    if (val) {
      if (val > Math.pow(10, 6)) {
        const q = val / Math.pow(10, 6)
        if (q > 100) {
          return `${numberWithCommas(q).toFixed(0)}M`
        }
        const digit = q.toFixed(1).split('.')[1]
        if (digit === '0') {
          return `${q.toFixed(0)}K`
        }
        return `${q.toFixed(1)}K`
      }

      if (val > Math.pow(10, 3)) {
        const q = val / Math.pow(10, 3)
        const digit = q.toFixed(1).split('.')[1]
        if (digit === '0') {
          return `${q.toFixed(0)}K`
        }
        return `${q.toFixed(1)}K`
      }

      return numberWithCommas(val)
    }
    return '-'
  }

  const numberWithCommas = x => {
    const parts = x.toString().split(".");
    parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    return parts.join(".");
  }

  return {
    toPercent,
    toMoney,
    toBigNumber,
  }
}
