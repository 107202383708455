import {
  computed, ref, readonly, provide,
} from '@vue/composition-api'
import { useUtils as useI18nUtils } from '@core/libs/i18n'
import useDate from '@/views/utils/useDate'
import { useRouter } from '@core/utils/utils'

export default function useSlotList() {
  const { route, router } = useRouter()
  const { isClosed } = useDate()
  const { t } = useI18nUtils()
  const currentSlots = ref([])
  const statusFilter = ref(null)
  const typeFilter = ref([])
  const channelFilter = ref([])
  const currentPage = ref(1)
  const sortBy = ref('')
  const isSortDirDesc = ref(true)

  const showAccepted = computed({
    get() {
      return !route.value.query.unAccepted
    },
    set(value) {
      if (value) {
        router.push({ name: route.value.name })
      } else {
        router.push({ name: route.value.name, query: { unAccepted: true } })
      }
    },
  })

  const setCurrentSlots = slots => {
    currentSlots.value = slots.map(slot => {
      const { status, ...copied } = slot
      copied.status = isClosed(slot.closeAt) ? 'CLOSED' : slot.status
      copied.clickRate = (slot.clicks / slot.impressions) * 100
      return copied
    })
  }

  const items = computed(() => {
    let data = currentSlots.value
    if (showAccepted.value) {
      data = currentSlots.value.filter(slot => slot.reviewStatus === 'ACCEPTED'
        && slot.status === (statusFilter.value || slot.status))
    } else {
      data = currentSlots.value.filter(slot => slot.reviewStatus !== 'ACCEPTED'
        && slot.reviewStatus === (statusFilter.value || slot.reviewStatus))
    }

    if (typeFilter.value.length > 0) {
      let result = []
      typeFilter.value.forEach(type => {
        result = [...result, ...data.filter(el => el.type === type.value)]
      })
      data = result
    }

    if (channelFilter.value.length > 0) {
      let result = []
      channelFilter.value.forEach(channelName => {
        result = [...result, ...data.filter(el => el.channel?.name === channelName)]
      })
      data = result
    }

    return data
  })

  const statusOptions = computed(() => {
    if (showAccepted.value) {
      const accepted = currentSlots.value.filter(slot => slot.reviewStatus === 'ACCEPTED')
      const activated = accepted.filter(slot => slot.status === 'ACTIVATED')
      const paused = accepted.filter(slot => slot.status === 'PAUSED')
      const closed = accepted.filter(slot => slot.status === 'CLOSED')
      return [
        {
          text: `${t('Accepted Slot')} (${accepted.length})`,
          value: null,
        },
        {
          text: `${t('Activated Slot')} (${activated.length})`,
          value: 'ACTIVATED',
        },
        {
          text: `${t('Paused Slot')} (${paused.length})`,
          value: 'PAUSED',
        },
        {
          text: `${t('Closed Slot')} (${closed.length})`,
          value: 'CLOSED',
        },
      ]
    }
    const unAccepted = currentSlots.value.filter(slot => slot.reviewStatus !== 'ACCEPTED')
    const pending = unAccepted.filter(slot => slot.reviewStatus !== 'PENDING')
    const rejected = unAccepted.filter(slot => slot.reviewStatus !== 'REJECTED')
    return [
      {
        text: `${t('Unaccepted Slot')} (${unAccepted.length})`,
        value: null,
      },
      {
        text: `${t('PENDING')} (${pending.length})`,
        value: 'PENDING',
      },
      {
        text: `${t('REJECTED')} (${rejected.length})`,
        value: 'REJECTED',
      },
    ]
  })

  const reversedOption = computed(() => {
    if (showAccepted.value) {
      const unAccepted = currentSlots.value.filter(slot => slot.reviewStatus !== 'ACCEPTED')
      return `${t('Unaccepted Slot')} (${unAccepted.length})`
    }
    const accepted = currentSlots.value.filter(slot => slot.reviewStatus === 'ACCEPTED')
    return `${t('Accepted Slot')} (${accepted.length})`
  })

  const channelOptions = computed(() => {
    const channels = []
    currentSlots.value.forEach(slot => {
      if (channels.findIndex(el => el === slot.channel?.name) === -1) {
        channels.push(slot.channel?.name)
      }
    })
    return channels
  })

  // *===============================================---*
  // *--------- Provide --------------------------------*
  // *===============================================---*
  // for SlotViewHeader
  provide('statusOptions', readonly(statusOptions))
  provide('reversedOption', readonly(reversedOption))
  provide('channelOptions', readonly(channelOptions))
  provide('showAccepted', showAccepted)
  provide('statusFilter', statusFilter)
  provide('channelFilter', channelFilter)
  provide('typeFilter', typeFilter)

  // for SlotViewTable & Footer
  provide('items', readonly(items))
  provide('currentPage', currentPage)
  provide('sortBy', sortBy)
  provide('isSortDirDesc', isSortDirDesc)

  // to replace current slot list
  provide('setCurrentSlots', setCurrentSlots)

  return {
    setCurrentSlots,
  }
}
