<template>
  <!-- Table Container Card -->
  <div class="table-content">
    <b-table
      :items="items"
      responsive
      :fields="tableColumns"
      primary-key="id"
      :sort-by.sync="sortBy"
      :sort-desc.sync="isSortDirDesc"
      show-empty
      empty-text="일치하는 데이터가 없습니다"
      class="position-relative"
    >
      <template v-slot:empty="scope">
        <div class="my-3 mx-2 text-center">
          {{ scope.emptyText }}
        </div>
      </template>

      <template #table-colgroup="scope">
        <col
          v-for="field in scope.fields"
          :key="field.key"
          :style="{ minWidth: '120px' }"
        >
      </template>

      <template #cell(name)="data">
        <b-link
          v-if="!isReadOnly"
          style="text-decoration: underline"
          class="font-weight-bold text-nowrap"
          @click="moveTo(data.item)"
        >
          {{ data.value }}
        </b-link>
        <div v-else>
          {{ data.value }}
        </div>
      </template>

      <!-- Column: Type -->
      <template #cell(type)="data">
        <span class="text-nowrap">
          {{ data.value }}
        </span>
      </template>

      <!-- Column: Status -->
      <template #cell(status)="data">
        <b-icon
          v-if="data.item.status==='ACTIVATED'"
          :style="{cursor: isReadOnly ? 'auto' : 'pointer'}"
          scale="1.5"
          :icon="resolveSlotStatus(data.item.status).icon"
          :variant="resolveSlotStatus(data.item.status).variant"
          @click="onTapPlaying(data.item)"
        />
        <b-icon
          v-else-if="data.item.status==='PAUSED'"
          :style="{cursor: isReadOnly ? 'auto' : 'pointer'}"
          scale="1.5"
          :icon="resolveSlotStatus(data.item.status).icon"
          :variant="resolveSlotStatus(data.item.status).variant"
          @click="onTapPaused(data.item)"
        />
        <div
          v-else
          :class="`text-${resolveSlotStatus(data.item.reviewStatus).variant} text-nowrap`"
          style="text-align: center"
        >
          {{ resolveSlotStatus(data.item.status).text }}
        </div>
      </template>

      <!-- Column: ReviewStatus -->
      <template #cell(reviewStatus)="data">
        <div :class="`text-${resolveReviewStatus(data.item.reviewStatus).variant}`">
          {{ resolveReviewStatus(data.item.reviewStatus).text }}
        </div>
      </template>

      <!-- Column: Start Date -->
      <template #cell(openAt)="data">
        <span class="text-nowrap">
          {{ data.value }}
        </span>
      </template>

      <!-- Column: End Date -->
      <template #cell(closeAt)="data">
        <span class="text-nowrap">
          {{ data.value }}
        </span>
      </template>

      <!-- Column: Modified Date -->
      <template #cell(modifiedAt)="data">
        <span class="text-nowrap">
          {{ data.value }}
        </span>
      </template>

      <!-- Column: Copy Script Action -->
      <template #cell(script)="data">
        <div class="text-nowrap">
          <feather-icon
            :id="`inventory-row-${data.item.id}-code-icon`"
            icon="CodeIcon"
            class="cursor-pointer"
            size="16"
            @click="copyScript(data.item)"
          />
        </div>
      </template>
    </b-table>

    <!-- modal alert pause -->
    <b-modal
      id="modal-alert-pause"
      ok-title="일시 중지하기"
      cancel-title="취소"
      ok-variant="primary"
      cancel-variant="outline-secondary"
      centered
      hide-header-close
      @ok="toggleIcon"
      @hide="selectedItem=null"
    >
      <b-card-text>
        현재 지면을 일시 중지하시겠습니까?
      </b-card-text>
    </b-modal>

    <!-- modal alert resume -->
    <b-modal
      id="modal-alert-resume"
      ok-title="운영하기"
      cancel-title="취소"
      ok-variant="primary"
      cancel-variant="outline-secondary"
      centered
      hide-header-close
      @ok="toggleIcon"
      @hide="selectedItem=null"
    >
      <b-card-text>
        현재 지면을 계속 운영하시겠습니까?
      </b-card-text>
    </b-modal>

  </div>
</template>

<script>
import {
  BTable, BLink, BModal, BCardText,
  BIcon,
} from 'bootstrap-vue'
import { computed, inject, ref } from '@vue/composition-api'
import useScriptCode from '@/views/utils/useScriptCode'
import router from '@/router'
import { channelRouteNames } from '@/router/routes/channel'
import useStatusResolver from '@/views/utils/useStatusResolver'
import useSlotTask from '@/views/utils/task/useSlotTask'
import useDate from '@/views/utils/useDate'
import useNumber from '@/views/utils/useNumber'
import { useUtils as useI18nUtils } from '@core/libs/i18n'
import { canWriteSlot } from '@/libs/acl/protection'

export default {
  components: {
    BTable,
    BLink,
    BIcon,
    BModal,
    BCardText,
  },
  methods: {
    onTapPlaying(item) {
      if (!this.isReadOnly) {
        this.selectedItem = item
        this.$bvModal.show('modal-alert-pause')
      }
    },
    onTapPaused(item) {
      if (!this.isReadOnly) {
        this.selectedItem = item
        this.$bvModal.show('modal-alert-resume')
      }
    },
  },
  setup() {
    const { copyToClipboard, generateScript } = useScriptCode()
    const { resolveSlotStatus, resolveReviewStatus } = useStatusResolver()
    const { parseDateTable, parseDateDeep } = useDate()
    const { toBigNumber, toPercent, toMoney } = useNumber()
    const { updateStatus, fetchSlotList } = useSlotTask()
    const { t } = useI18nUtils()
    const setCurrentSlots = inject('setCurrentSlots')
    const showAccepted = inject('showAccepted')
    const items = inject('items')
    const currentPage = inject('currentPage')
    const sortBy = inject('sortBy')
    const isSortDirDesc = inject('isSortDirDesc')
    const selectedItem = ref(null)

    const isReadOnly = computed(() => !canWriteSlot())
    const copyScript = slot => copyToClipboard(generateScript(slot.id, slot.channelAccountId))

    const tableColumns = computed(() => [
      { key: 'name', label: t('slotName') },
      { key: 'type', label: t('slotType'), formatter: val => t(val) },
      { key: (showAccepted.value) ? 'status' : 'reviewStatus', label: t('Status') },
      {
        key: 'impressions',
        label: t('Impressions'),
        sortable: true,
        formatter: toBigNumber,
      },
      {
        key: 'clicks',
        label: t('Clicks'),
        sortable: true,
        formatter: toBigNumber,
      },
      {
        key: 'clickRate',
        label: t('ClickRate'),
        sortable: true,
        formatter: toPercent,
      },
      { key: 'profit', label: t('Profit'), formatter: toMoney },
      { key: 'openAt', label: t('openAt'), formatter: val => parseDateTable(val) },
      { key: 'closeAt', label: t('closeAt'), formatter: val => parseDateTable(val) },
      { key: 'script', label: t('Copy Code') },
      { key: 'modifiedAt', label: t('ModifiedAt'), formatter: val => parseDateDeep(val) },
    ])
    const toggleIcon = async () => {
      const newItem = { ...selectedItem.value }
      if (newItem.status === 'ACTIVATED') {
        newItem.status = 'PAUSED'
      } else {
        newItem.status = 'ACTIVATED'
      }
      await updateStatus(newItem)
      const slots = await fetchSlotList()
      setCurrentSlots(slots)
    }
    const moveTo = item => {
      router.push({ name: channelRouteNames.slotView, params: { slotId: item.id } })
    }
    return {
      selectedItem,
      moveTo,
      items,
      tableColumns,
      currentPage,
      sortBy,
      isSortDirDesc,
      isReadOnly,
      resolveSlotStatus,
      resolveReviewStatus,
      toggleIcon,
      copyScript,
    }
  },
}
</script>
<style lang="scss">
.modal-header {
  border-bottom: 0 none;
}

.modal-footer {
  border-top: 0 none;
}
</style>
