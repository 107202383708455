import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { useToast } from 'vue-toastification/composition'
import { useClipboard } from '@vueuse/core'

export default function useScriptCode() {
  const toast = useToast()
  const { copy } = useClipboard()
  const copyToClipboard = value => {
    copy(value).then(() => {
      toast({
        component: ToastificationContent,
        position: 'top-center',
        props: {
          title: '코드가 복사되었습니다',
          icon: 'CheckIcon',
          variant: 'success',
        },
      })
    }).catch(() => {
      toast({
        component: ToastificationContent,
        position: 'top-center',
        props: {
          title: '오류가 발생하여 코드를 복사하지 못했습니다.',
          icon: 'XCircleIcon',
          variant: 'danger',
        },
      })
    })
  }

  const generateScript = (channel, slot) => `<!-- 공통 스크립트 -->
<script async src="https://ad.synergyx.kr/script/ad.js"></script>
<!-- /공통 스크립트 -->
      
<!-- 광고 단위 이름 -->
<div class="ads"
     style="display:block"
     data-ad-channel=${channel}
     data-ad-slot=${slot}
     data-ad-format="auto"></div>
<script>(adbysynergyx = window.adbysynergyx || []).push({});</script>`

  return {
    copyToClipboard,
    generateScript,
  }
}
