<template>
  <div class="header-row">
    <filter-group-toggle />

    <search-bar
      placeholder="지면명 검색"
      style="margin-right: auto"
    />

    <!-- Add Button -->
    <b-button
      v-if="!isReadOnly"
      class="add-btn text-nowrap"
      variant="primary"
      size="md"
      @click="register"
    >
      <b-icon
        icon="plus"
      />
      지면 등록
    </b-button>
  </div>
</template>
<script>

import {
  BButton, BIcon,
} from 'bootstrap-vue'
import { computed, inject } from '@vue/composition-api'
import router from '@/router'
import { channelRouteNames } from '@/router/routes/channel'
import { canWriteSlot } from '@/libs/acl/protection'
import SearchBar from '@/views/components/table/SearchBar.vue'
import FilterGroupToggle from '@/views/components/table/FilterGroupToggle.vue'

export default {
  components: {
    FilterGroupToggle,
    BIcon,
    BButton,
    SearchBar,
  },
  setup() {
    const showAccepted = inject('showAccepted')
    const reversedOption = inject('reversedOption')

    const register = () => {
      router.push({ name: channelRouteNames.registerSlot })
    }

    const isReadOnly = computed(() => !canWriteSlot())

    return {
      showAccepted,
      register,
      reversedOption,
      isReadOnly,
    }
  },
}
</script>

<style lang="scss" scoped>
@import '~flatpickr/dist/flatpickr.css';
</style>
