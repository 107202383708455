<template>
  <div class="d-flex align-items-center justify-content-center">
    <!-- Pagination -->
    <b-pagination
      v-model="currentPage"
      :total-rows="totalLength"
      :per-page="10"
      first-number
      last-number
      class="mb-0 mt-1 mt-sm-0"
      prev-class="prev-item"
      next-class="next-item"
    >
      <template #prev-text>
        <b-icon
          icon="chevron-left"
          scale="1"
        />
      </template>
      <template #next-text>
        <b-icon
          icon="chevron-right"
          scale="1"
        />
      </template>
    </b-pagination>
  </div>
</template>
<script>
import {
  BIcon, BPagination,
} from 'bootstrap-vue'
import {
  inject, computed,
} from '@vue/composition-api'

export default {
  components: {
    BIcon,
    BPagination,
  },
  setup() {
    const currentPage = inject('currentPage')
    const items = inject('items')
    const totalLength = computed(() => items.length)

    return {
      currentPage,
      totalLength,
    }
  },
}
</script>
