/* eslint-disable object-curly-newline */
import { ref } from '@vue/composition-api'
import store from '@/store'

export default function useSlotTask() {
  const loading = ref(false)
  const error = ref(null)

  const fetchSlotList = async queryString => {
    loading.value = true
    const channelAccountId = store.getters['account/accountId']
    return store.dispatch('slot/fetchSlotList', {
      channelAccountId,
      q: queryString ?? '',
    }).then(response => {
      if (response.data.status === 2000) {
        return response.data.payload
      }
      throw response.data.payload
    }).catch(e => {
      error.value = e
    }).finally(() => {
      loading.value = false
    })
  }

  const fetchSlot = async id => {
    loading.value = true
    const channelAccountId = store.getters['account/accountId']
    return store.dispatch('slot/fetchSlot', { channelAccountId, slotId: id })
      .then(response => {
        if (response.data.status === 2000) {
          return response.data.payload
        }
        throw response.data.payload
      })
      .catch(e => {
        error.value = e
      })
      .finally(() => {
        loading.value = false
      })
  }

  const uploadFile = async (slot, file) => store.dispatch('slot/uploadFile', { slot, file })

  const createSlot = slot => store.dispatch('slot/createSlot', slot)
    .then(async response => {
      const newSlot = response.data.payload
      if (slot.imageFile) {
        await uploadFile(newSlot, slot.imageFile)
      }

      return {
        ...newSlot,
        channelAccountId: slot.channelAccountId,
      }
    })
    .catch(e => {
      error.value = e
    })
    .finally(() => {
      loading.value = false
    })

  const updateSlot = slot => {
    loading.value = true
    return store.dispatch('slot/updateSlot', slot)
      .then(async response => {
        const newSlot = response.data.payload
        if (slot.imageFile) {
          await uploadFile(newSlot, slot.imageFile)
        }
        return {
          ...newSlot,
          channelAccountId: slot.channelAccountId,
        }
      })
      .catch(e => {
        error.value = e
      })
      .finally(() => {
        loading.value = false
      })
  }

  const updateStatus = slot => {
    loading.value = true
    return store.dispatch('slot/updateSlotStatus', slot)
      .then(response => response.data)
      .catch(e => {
        error.value = e
      })
      .finally(() => {
        loading.value = false
      })
  }

  const deleteSlot = async slot => {
    loading.value = true
    return store.dispatch('slot/deleteSlot', slot)
      .then(response => response.data.payload)
      .catch(e => {
        error.value = e
      })
      .finally(() => {
        loading.value = false
      })
  }

  const requestReview = async slot => {
    loading.value = true
    return store.dispatch('slot/requestReview', slot)
      .then(response => {
        if (response.data.status === 2000) {
          return response.data.payload
        }
        throw response.data.payload
      })
      .catch(e => {
        error.value = e
      })
      .finally(() => {
        loading.value = false
      })
  }

  return {
    loading,
    error,
    fetchSlotList,
    fetchSlot,
    createSlot,
    updateSlot,
    updateStatus,
    requestReview,
    deleteSlot,
  }
}
