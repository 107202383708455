<template>
  <div class="filter-group">
    <b-form-radio-group
      v-model="filter"
      :options="options"
      buttons
      button-variant="outline-primary"
    />
    <b-button
      key="mode"
      class="mx-1"
      variant="outline-primary"
      @click="showAccepted = !showAccepted"
    >
      {{ reversedOption }}
    </b-button>
  </div>
</template>
<script>

import { BFormRadioGroup, BButton } from 'bootstrap-vue'
import { inject } from '@vue/composition-api'

export default {
  components: {
    BFormRadioGroup,
    BButton,
  },
  setup() {
    const options = inject('statusOptions')
    const filter = inject('statusFilter')
    const showAccepted = inject('showAccepted')
    const reversedOption = inject('reversedOption')

    return {
      showAccepted,
      reversedOption,
      options,
      filter,
    }
  },
}
</script>
