<template>
  <div class="search-bar">
    <b-input-group class="input-group-merge">
      <b-form-input
        v-model="localSearch"
        :placeholder="placeholder"
      />
      <b-input-group-append
        v-show="localSearch"
        is-text
      >
        <feather-icon
          class="cursor-pointer"
          icon="XIcon"
          @click="onCancel"
        />
      </b-input-group-append>
      <b-input-group-append>
        <b-button
          variant="primary"
          @click="onSubmit"
        >
          검색
        </b-button>
      </b-input-group-append>
    </b-input-group>
  </div>
</template>
<script>

import {
  BButton, BFormInput,
  BInputGroup, BInputGroupAppend,
} from 'bootstrap-vue'
import { inject, ref } from '@vue/composition-api'

export default {
  components: {
    BButton,
    BFormInput,
    BInputGroup,
    BInputGroupAppend,
  },
  props: {
    placeholder: {
      type: String,
      required: true,
    },
  },
  setup() {
    const searchQuery = inject('searchQuery')
    const localSearch = ref(searchQuery.value)

    const onSubmit = () => {
      searchQuery.value = localSearch.value.toLowerCase()
    }

    const onCancel = () => {
      searchQuery.value = ''
    }

    return {
      localSearch,
      onSubmit,
      onCancel,
    }
  },
}
</script>

<style lang="scss" scoped>
.search-bar {
  width: 20%;
  min-width: 250px;
  display: inline-block;
  margin-right:20px;
}
</style>
