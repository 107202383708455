/* eslint-disable object-curly-newline */
import { useUtils as useI18nUtils } from '@core/libs/i18n'

export default function useStatusResolver() {
  const { t } = useI18nUtils()

  const resolveSlotStatus = status => {
    if (status === 'ACTIVATED') {
      return { variant: 'success', icon: 'play-circle' }
    }
    if (status === 'PAUSED') {
      return { variant: 'warning', icon: 'pause-circle' }
    }

    if (status === 'CLOSED') {
      return { variant: 'dark', text: t('Closed Slot') }
    }

    return { variant: 'dark', text: '-' }
  }

  const resolveCampaignStatus = status => {
    if (status === 'ACTIVATED') {
      return { variant: 'success', icon: 'play-circle' }
    }
    if (status === 'PAUSED') {
      return { variant: 'warning', icon: 'pause-circle' }
    }

    if (status === 'CLOSED') {
      return { variant: 'dark', text: t('Closed Campaign') }
    }

    return { variant: 'dark', text: '-' }
  }

  const resolveReviewStatus = status => {
    if (status === 'PENDING') {
      return { variant: 'dark', text: t('PENDING') }
    }

    if (status === 'REJECTED') {
      return { variant: 'dark', text: t('PENDING') }
    }

    return { variant: 'dark', text: '-' }
  }

  return {
    resolveReviewStatus,
    resolveSlotStatus,
    resolveCampaignStatus,
  }
}
